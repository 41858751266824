<template>
	<div class="task-hall">
		<Header/>
		<!-- <div class="task-hall-banner" ref="pos"><img src="@/assets/freelancer.jpg" alt="" /></div> -->
		<div class="task-hall-banner" ref="pos"><!--      <img src="@/assets/banner/task-banner.jpg" alt="" />--></div>
		<div class="task-hall-center">
			<!-- 分为左右两部分 -->
			<div class="banner-center-bottom-left">
				<div class="banner-center-bottom-left-center">
					<!--最近搜索  -->
					<div class="banner-center-bottom-left-search">
						<strong>最近搜索</strong>
						<b @click="clearHistorySearch" class="f-12 h-57 ml-10">清除</b>
						<!-- 下面是搜索的内容 -->
						<div class="banner-center-bottom-left-search-center">
							<span @click="toKeySearch(hisItem)" v-for="(hisItem, hisKey) in currentSearchWorks" :key="hisKey">{{ hisItem }}</span>
						</div>
						<div class="mb-20"></div>
					</div>
					<!-- 行业分类 -->
					<div class="banner-center-bottom-left-classify">
						<el-row class="tac">
							<h5>行业分类</h5>
							<el-col :span="14" style="width: 245px">
								<el-menu default-active="2" class="el-menu-vertical-demo">
									<el-submenu v-for="(cate, index) in categorys" :index="String(cate.id)" :key="index">
										<template slot="title">
											<span>{{ cate.name }}</span>
										</template>

										<el-menu-item-group v-if="cate.children.length > 0">
											<el-menu-item v-for="(cat, key) in cate.children" :key="key" :index="String(cat.id)">
												<el-radio @change.native="changeCategory(cat)" v-model="radio" :label="cat.id">{{ cat.name }}</el-radio>
											</el-menu-item>
										</el-menu-item-group>

										<el-menu-item v-if="cate.children.length == 0" :index="String(cate.url)">
											<el-radio v-model="radio" :label="cate.id">{{ cate.name }}</el-radio>
										</el-menu-item>
									</el-submenu>
								</el-menu>
							</el-col>
						</el-row>
					</div>
					<!-- 托管资金 -->
					<div class="banner-center-money">
						<h2>资金托管方式</h2>
						<el-checkbox-group v-model="searchParams.pay_channel" @change="bindCheckBox">
							<el-checkbox true-label="1" false-label="2">平台</el-checkbox>
							<el-checkbox true-label="2" false-label="1">银行</el-checkbox>
						</el-checkbox-group>
					</div>
					<!-- 酬劳 -->

					<div class="banner-center-remuneration">
						<h2>酬劳</h2>
						<div class="banner-remuneration-fixed-money">
							<el-checkbox true-label="1" v-model="searchParams.pay_way" @change="changePayWay">固定薪酬任务</el-checkbox>
							<!-- <br /> -->
							<div class="banner-remuneration-fixed-money-input">
								<b>￥</b>
								<input
									type="number"
									:disabled="searchParams.pay_way != 1 ? true : false"
									v-model="searchParams.fixed_budget_min"
									placeholder="最低"
									style="margin-left:-15px"
								/>
								<span>至</span>
								<b class="taskb">￥</b>
								<input type="number" :disabled="searchParams.pay_way != 1 ? true : false" v-model="searchParams.fixed_budget_max" placeholder="最高" />
							</div>
						</div>
						<div class="banner-remuneration-hour-task">
							<el-checkbox true-label="2" v-model="searchParams.pay_way" @change="changePayWay">按时计薪任务</el-checkbox>
							<!-- <br /> -->
							<div class="banner-remuneration-fixed-money-input">
								<b>￥</b>
								<input
									type="number"
									:disabled="searchParams.pay_way != 2 ? true : false"
									v-model="searchParams.hourly_budget_min"
									placeholder="最低"
									style="margin-left:-15px"
								/>
								<span>至</span>
								<b class="taskb">￥</b>
								<input type="number" :disabled="searchParams.pay_way != 2 ? true : false" v-model="searchParams.hourly_budget_max" placeholder="最高" />
							</div>
						</div>
						<!-- 有效期 -->
						<!-- <div class="banner-remuneration-date">
							<el-select v-model="value1" placeholder="请选择">
								<el-option v-for="item in date" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</div> -->
					</div>
					<!-- 任务状态 -->
					<div class="banner-center-state">
						<h2>任务状态</h2>
						<el-checkbox-group v-model="searchParams.status" @change="changeStatus">
							<el-checkbox true-label="effective" :false-label="0">所有有效期内的任务</el-checkbox>
							<br />
							<el-checkbox true-label="all" :false-label="0">包括已结束的任务</el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
			</div>
			<div class="task-hall-center-right">
				<!-- 搜索框 -->
				<div class="liberty-hall-search">
          <el-input
              placeholder="输入项目的标题/名称来检索"
              :autofocus="true"
              v-model.trim="searchFont">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <el-button slot="append" @click="search">搜索关键字</el-button>
          </el-input>
<!--					<input type="text" placeholder="" v-on:keyup.enter="search" v-model="" />-->
<!--					<i class="iconfont icon-search"></i>-->
<!--					<button @click="search">搜索关键字</button>-->
				</div>
				<!-- 搜索框下面显示的内容 -->
				<div class="banner-center-bottom-right-search-border">
					<div class="banner-center-bottom-right-search-border1" v-for="(searchItem, searchIndex) in searchParams" :key="searchIndex" v-show="searchItem != ''">
						<div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'kw'">
							标题：{{ searchItem }}
							<i class="iconfont icon-close wh-12 ml-10" @click="clearTag(searchIndex)"></i>
						</div>
						<div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'pay_way'">
							计价方式：
							<i v-for="payitem in payWayList" :key="payitem.value" v-show="payitem.value == searchItem">{{ payitem.label }}</i>
							<i class="iconfont icon-close wh-12 ml-10" @click="clearTag(searchIndex)"></i>
						</div>
						<div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'fixed_budget_min' && searchParams.pay_way == 1">
							最低预算：
							<i>{{ searchItem }} 元</i>
							<i class="iconfont icon-close wh-12 ml-10" @click="clearTag(searchIndex)"></i>
						</div>
						<div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'fixed_budget_max' && searchParams.pay_way == 1">
							最高预算：
							<i>{{ searchItem }} 元</i>
							<i class="iconfont icon-close wh-12 ml-10" @click="clearTag(searchIndex)"></i>
						</div>
						<div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'hourly_budget_min' && searchParams.pay_way == 2">
							最低预算：
							<i>{{ searchItem }} 元</i>
							<i class="iconfont icon-close wh-12 ml-10" @click="clearTag(searchIndex)"></i>
						</div>
						<div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'hourly_budget_max' && searchParams.pay_way == 2">
							最高预算：
							<i>{{ searchItem }} 元</i>
							<i class="iconfont icon-close wh-12 ml-10" @click="clearTag(searchIndex)"></i>
						</div>
						<div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'pay_channel'">
							资金托管方式：
							<i>{{ searchItem == 1 ? '平台' : '银行' }}</i>
							<i class="iconfont icon-close wh-12 ml-10" @click="clearTag(searchIndex)"></i>
						</div>
						<div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'status'">
							任务状态:：
							<i>{{ searchItem == 'effective' ? '所有有效期内的任务' : '包括已结束的任务' }}</i>
							<i class="iconfont icon-close wh-12 ml-10" @click="clearTag(searchIndex)"></i>
						</div>
						<div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'sort'">
							排序：
							<i v-for="oritem in orderByList" :key="oritem.value" v-show="oritem.value == searchItem">{{ oritem.label }}</i>
							<i class="iconfont icon-close wh-12 ml-10" @click="clearTag(searchIndex)"></i>
						</div>

						<div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'skill_cat_id'">
							分类：
							<i>{{ searchParams.cat_name }}</i>
							<i class="iconfont icon-close wh-12 ml-10" @click="clearTag(searchIndex)"></i>
						</div>
					</div>
				</div>
				<!-- 内容部分 -->
				<div class="liberty-hall-list">
					<div class="liberty-hall-list-header">
						<div class="liberty-hall-list-header-content">
							<div class="liberty-hall-list-header-left">
								<span>共找到{{ pages.total }}个项目</span>
								<div class="liberty-hall-list-header-left-refresh" @click="clearSearch">
									<i class="iconfont icon-change b mr-10"></i>
									<strong class="h-57">刷新</strong>
								</div>
							</div>
							<div class="liberty-hall-list-header-right">
								<el-select size="mini" v-model="value" placeholder="请选择" @change="selectSortHandler" clearable>
									<el-option v-for="item in orderByList" :key="item.value" :label="item.label" :value="item.value" />
								</el-select>
							</div>
						</div>
					</div>
					<div class="task-content-font" v-loading="loading">
						<div class="task-hall-center-content" v-for="(item, index) in taskList" :key="index">
							<div class="task-content">
								<div class="task-content-left">
									<h2 @click="toInfo(item)">{{ item.subject }}</h2>
									<p @click="toInfo(item)">
										{{ item.content }}
										<button type="button" v-if="item.content.length > 100">更多</button>
									</p>
									<ul class="f-12">
										<li v-for="(skill, skillIndex) in item.skills" :key="skillIndex">{{ skill.name }}</li>
									</ul>
								</div>
								<div class="task-content-right">
									<h2>预算￥{{ item.budget_amount }}</h2>
									<button @click="toInfo(item)">参与竞标</button>
									<br />
									<span>已有{{ item.bid_counts }}人竞标</span>
								</div>
							</div>
							<div class="task-content-bottom-img">
								<ul>
									<li v-if="item.pay_way == 2">
										<i class="iconfont icon-hour b wh-18 mr-10"></i>
										<span class="f-12 h-57">按小时收费</span>
									</li>
									<li v-if="item.pay_way == 1">
										<i class="iconfont icon-definite b wh-18 mr-10"></i>
										<span class="f-12 h-57">固定价格</span>
									</li>
									<li v-if="item.pay_cycle == 1">
										<i class="iconfont icon-week b wh-18 mr-10"></i>
										<span class="f-12 h-57">按小周收费</span>
									</li>
									<li v-if="item.pay_cycle == 2">
										<i class="iconfont icon-month b wh-18 mr-10"></i>
										<span class="f-12 h-57">按月收费</span>
									</li>
									<li>
										<i class="iconfont icon-personal b wh-18 mr-10"></i>
										<span class="f-12 h-57">雇佣{{ item.plan_hire_count }}人</span>
									</li>
									<li>
										<el-rate v-model="startValue" disabled show-score text-color="#ff9900" score-template="{value}"/>
										<span class="f-12 h-57 ml-10">(0个评论)</span>
									</li>
									<li>
										<i class="iconfont icon-time b wh-18 mr-10"></i>
										<span class="f-12 h-57" v-if="item.publish_day == '前'">刚刚发布</span>
										<span class="f-12 h-57" v-else>{{ item.publish_day }}发布</span>
									</li>
								</ul>
							</div>
						</div>
						<!-- 内容部分 -->
						<div v-if="taskList.length == 0"><el-empty description="没有找到此检索项目,换个检索词试试？"/></div>
						<div class="block">
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								prev-text="上一页"
								next-text="下一页"
								:current-page="pages.page"
								:page-sizes="[10, 20, 30]"
								:page-size="pages.page_size"
								layout="prev, pager, next,->,total,jumper"
								:total="pages.total"
								background
								:hide-on-single-page="true"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
import { getTaskHall, getSkillsCategory } from '@/api/task';
import cookie from 'vue-cookie';
export default {
	name: 'taskIndex',
	data() {
		return {
			options: [
				{
					value: '选项1',
					label: '我的收藏'
				},
				{
					value: '选项2',
					label: '最新的'
				},
				{
					value: '选项3',
					label: '最老的'
				},
				{
					value: '选项4',
					label: '最低价格'
				},
				{
					value: '选项5',
					label: '最高价格'
				},
				{
					value: '选项6',
					label: '最少的出价'
				},
				{
					value: '选项七',
					label: '大多数的出价'
				}
			],
			value: '',
			radio: '1',
			//左侧多选框
			checkList: [],
			//最低 固定薪酬任务
			minimumf: '',
			// 最高
			highestf: '',
			// 有效期限
			value1: '',
			// 分类 一口价 关键字
			searchParams: {
				sort_type: '',
				kw: '', // 关键字
				pay_way: '', //收费类型
				status: '',
				pay_channel: '',
				fixed_budget_min: '',
				fixed_budget_max: '',
				hourly_budget_min: '',
				hourly_budget_max: '',
				skill_cat_id: '',
				cat_name: ''
			},
			payWayList: [
				{
					value: 1,
					label: '固定酬劳'
				},
				{
					value: 2,
					label: '按时酬劳'
				}
			],
			orderByList: [
				{
					value: 1,
					label: '最新发布'
				},
				{
					value: 0,
					label: '综合排序'
				}
			],
			page: 1,
			limit: 3,
			total: 0,
			startValue: 5,

			// lipeng
			pages: {
				page: 1,
				page_size: 10,
				total: 0
			},
			taskList: [],
			loading: false,
			historySearchKey: ['IT和网络', '网站', '数据科学与技术'],
			categorys: [],
			// lipeng end
			date: [
				{
					value: '选项1',
					label: '竞标中'
				},
				{
					value: '选项2',
					label: '3天内新发布'
				},
				{
					value: '选项3',
					label: '7天内新发布'
				},
				{
					value: '选项4',
					label: '1个月内发布'
				}
			],
			searchFont: '',
			currentSearchWorks: []
		};
	},
	created() {
		this.loadTwoLevelCategorys({ level: 2 });

		const queryData = this.$route.params

		console.log(queryData)
		if( queryData.id ) {
			this.changeCategory(queryData)
		}else{
			this.loadTaskList(this.pages);
		}
	},
	mounted() {
		const cookieSearchWorks = cookie.get('searchHistory');
		if (cookieSearchWorks != null) {
			this.currentSearchWorks = cookieSearchWorks.split(',');
		}
	},
	methods: {
		// 清除sous记录
		clearSearch() {
			this.searchParams = {
				sort_type: '',
				kw: '', // 关键字
				pay_way: '', //收费类型
				status: '',
				pay_channel: '',
				fixed_budget_min: '',
				fixed_budget_max: '',
				hourly_budget_min: '',
				hourly_budget_max: '',
				skill_cat_id: '',
				cat_name: ''
			};
			this.loadTaskList({ page: 1, page_size: 10 });
		},
		// 指定展开的sub-menu
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		// 指定收起的sub-menu
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		search() {
			// this.$refs.pos.scrollIntoView({
			// 	// 平滑滚动
			// 	behavior: 'smooth',
			// 	block: 'nearest',
			// 	inline: 'nearest'
			// });
			this.searchParams.kw = this.searchFont;
			this.loadTaskList(this.searchParams);
			// 添加搜索历史
			this.addSearchHistory(this.searchFont);
		},
		addSearchHistory(works) {
			let theSearchWorks = Array();
			const cookieSearchWorks = cookie.get('searchHistory');
			if (works != '' && cookieSearchWorks != null) {
				let workArray = cookieSearchWorks.split(',');
				let isNewWorks = true;
				workArray.map(item => {
					theSearchWorks.push(item);
					if (item == works) {
						isNewWorks = false;
					}
				});
				if (isNewWorks) {
					theSearchWorks.push(works);
				}
			} else if (works != '') {
				theSearchWorks.push(works);
			}

			this.currentSearchWorks = theSearchWorks;
			cookie.set('searchHistory', theSearchWorks, 1);
		},
		clearHistorySearch() {
			this.currentSearchWorks = [];
			cookie.delete('searchHistory');
		},
		toKeySearch(keyworks) {
			this.searchParams.kw = keyworks;
			this.loadTaskList(this.searchParams);
		},
		async loadTwoLevelCategorys(data) {
			const res = await getSkillsCategory(data);
			if (res.statusCode == 200) {
				this.categorys = res.data;
			}
		},
		async loadTaskList(data) {
			this.loading = true;
			const res = await getTaskHall(data);
			if (res.statusCode == 200) {
				this.taskList = res.data.list;
				this.pages = {
					page: Number(res.data.pages.page),
					page_size: Number(res.data.pages.page_size),
					total: Number(res.data.pages.total)
				};
				this.loading = false;
			} else {
				this.$notify({
					type: 'error',
					title: '通知',
					message: '数据加载出现了一些问题!'
				});
				this.loading = false;
			}
		},
		clearTag(tagIndex) {
			switch (tagIndex) {
				case 'kw':
					this.searchParams.kw = '';
					break;
				case 'sort_type':
					this.searchParams.sort_type = '';
					break;
				case 'status':
					this.searchParams.status = '';
					break;
				case 'pay_way':
					this.searchParams.pay_way = '';
					break;
				case 'pay_channel':
					this.searchParams.pay_channel = '';
					break;
				case 'fixed_budget_min':
					this.searchParams.fixed_budget_min = '';
					break;
				case 'fixed_budget_max':
					this.searchParams.fixed_budget_max = '';
					break;
				case 'hourly_budget_min':
					this.searchParams.hourly_budget_min = '';
					break;
				case 'hourly_budget_max':
					this.searchParams.hourly_budget_max = '';
					break;
				case 'skill_cat_id':
					this.searchParams.skill_cat_id = '';
					this.searchParams.cat_name = '';
					break;
			}
			this.loadTaskList(this.searchParams);
		},
		handleSizeChange(val) {
			this.pages.page_size = val;
			this.loadTaskList(this.pages);
		},
		handleCurrentChange(val) {
			this.pages.page = val;
			this.loadTaskList(this.pages);
		},
		bindCheckBox(value) {
			this.searchParams.pay_channel = value;
			this.search();
		},
		changePayWay(value) {
			this.searchParams.pay_way = value;
			this.searchParams.fixed_budget_max = '';
			this.searchParams.fixed_budget_min = '';
			this.searchParams.hourly_budget_max = '';
			this.searchParams.hourly_budget_min = '';
			this.search();
		},
		changeStatus(value) {
			this.searchParams.status = value;
			this.search();
		},
		changeCategory(cate) {
			this.searchParams.skill_cat_id = cate.id;
			this.searchParams.cat_name = cate.name;
			this.search();
		},
		toInfo(obj) {
			this.$router.push('/task/info/' + obj.sn);
		},
		selectSortHandler(val) {
			this.searchParams.sort_type = val;
			this.search();
		}
	}
};
</script>

<style lang="less" scoped>
/deep/.el-loading-mask {
	z-index: unset;
}
header {
	margin-bottom: unset;
}
.task-hall {
	background-color: #f8f8f8;
}
.task-hall-banner {
	width: 100%;
	height: 350px;
	margin: 0 auto;
	background-image: url(~@/assets/banner/task-banner.jpg);
	background-size: cover;
}
.task-hall-center {
	width: 1200px;
	margin: 0 auto;
	margin-top: -300px;
	display: flex;
	justify-content: space-between;
	/* 左侧 */
	.banner-center-bottom-left {
		width: 285px;
		background: #fff;
		display: flex;
		flex-wrap: wrap;
		.banner-center-bottom-left-center {
			width: 245px;
			margin: 0 auto;
		}
		/* 最近搜索 */
		.banner-center-bottom-left-search {
			width: 245px;
			margin-top: 40px;
			h2 {
				font-size: 18px;
			}
			b {
				cursor: pointer;
			}
			.banner-center-bottom-left-search-center {
				margin-top: 20px;
				display: flex;
				flex-wrap: wrap;
				span {
					cursor: pointer;
					display: block;
					padding: 4px 9px;
					border: 1px solid #b5b5b5;
					border-radius: 10px;
					margin: 0 10px 10px 0px;
					font-size: 12px;
				}
			}
		}
		/* 行业分类 */
		.banner-center-bottom-left-classify {
			width: 245px;
			max-height: 400px;
			border-top: 1px solid #e4e7ed;
			border-bottom: 1px solid #e4e7ed;
			margin-bottom: 10px;
			overflow: hidden;
			h5 {
				margin-top: 20px;
				font-size: 18px;
				margin-bottom: 10px;
			}
			.tac {
				max-height: 400px;
				overflow: auto;
				width: 245px;
			}
			/deep/ .el-submenu__title {
				padding: 0px !important;
			}
			/deep/ .el-menu-item {
				padding: 0 !important;
			}
			/deep/ .el-submenu__title {
				line-height: 34px;
				height: 34px;
			}
			/deep/ .el-menu-item {
				height: 34px;
				font-size: 12px;
				position: relative;
				top: -15px;
			}
			/deep/ .el-radio__label {
				font-size: 12px;
			}
		}
		//托管资金
		.banner-center-money {
			border-bottom: 1px solid #e4e7ed;
			h2 {
				margin-top: 20px;
				font-size: 18px;
				margin-bottom: 20px;
			}
			/deep/.el-checkbox-group {
				margin-bottom: 20px;
			}
		}
		//酬劳
		.banner-center-remuneration {
			max-height: 257px;
			padding-bottom: 20px;
			border-bottom: 1px solid #e4e7ed;
			h2 {
				margin-top: 20px;
				font-size: 18px;
				margin-bottom: 20px;
			}
			.banner-remuneration-fixed-money,
			.banner-remuneration-hour-task {
				.banner-remuneration-fixed-money-input {
					margin-top: 10px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					input {
						width: 100px;
						height: 32px;
						text-indent: 25px;
					}
					b {
						position: relative;
						left: 8px;
						color: #575757;
					}
					.taskb {
						position: relative;
						left: 22px;
						color: #575757;
					}
					span {
						position: relative;
						left: 8px;
					}
				}
			}
			.banner-remuneration-hour-task {
				margin-top: 20px;
			}
			//   有效期
			.banner-remuneration-date {
				margin-top: 5px;
				/deep/.el-select {
					width: 245px;
				}
			}
		}
		// 任务状态
		.banner-center-state {
			margin-top: 20px;
			padding-bottom: 40px;
			h2 {
				font-size: 18px;
			}
			/deep/.el-checkbox {
				margin-top: 20px;
			}
		}
	}
	.task-hall-center-right {
		width: 895px;
		.liberty-hall-search {
			font-size: 18px;
			width: 815px;
			/deep/.el-icon-search {
				font-size: 22px;
				position: relative;
				top: 2px;
				left: 10px;
			}
			/deep/.el-input-group__append,
			.el-input-group__prepend {
				background-color: transparent;
				border: none;
				vertical-align: middle;
				margin-left: -20px;
			}
			/deep/.el-input__inner {
				width: 745px;
				height: 50px;
				line-height: 50px;
				margin-top: 0px;
				text-indent: 25px;
			}
			//input {
			//	width: 720px;
			//	height: 50px;
			//	background: #ffffff;
			//	text-indent: 74px;
			//	font-size: 18px;
			//	outline: none;
			//	border: none;
			//}
			button {
				width: 150px;
				height: 50px;
				background: #00a2e6;
				font-size: 18px;
				color: #fff;
			}
		}
		/*  搜索框下面显示的内容*/
		.banner-center-bottom-right-search-border {
			margin-top: 10px;
			display: flex;
			line-height: 20px;
			font-size: 12px;
			.banner-center-bottom-right-search-border1,
			.banner-center-bottom-right-search-border2,
			.banner-center-bottom-right-search-border3 {
				display: flex;
				span {
					margin: 0 20px 0 10px;
					color: #575757;
					cursor: pointer;
				}
			}
			.banner-center-bottom-right-search-border1-box,
			.banner-center-bottom-right-search-border2-box,
			.banner-center-bottom-right-search-border3-box {
				background-color: #00a2e6;
				padding: 0 10px;
				border-radius: 10px;
				color: #fff;
				cursor: pointer;
				margin-right: 5px;
			}
		}
		.liberty-hall-list {
			min-height: 1120px;
			margin-top: 10px;
			width: 895px;
			background: #ffffff;
			.liberty-hall-list-header {
				width: 895px;
				.liberty-hall-list-header-content {
					height: 72px;
					width: 815px;
					margin: 0 auto;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.liberty-hall-list-header-left {
						line-height: 72px;
						display: flex;
						.liberty-hall-list-header-left-refresh {
							cursor: pointer;
						}
						img {
							width: 16px;
							height: 16px;
							margin-right: 10px;
						}
						span {
							color: #474747;
							font-size: 14px;
							margin-right: 20px;
						}
					}
				}
			}
			.task-hall-center-content {
				background-color: #fff;
				//border-top: 1px solid #e4e7ed;
        margin-bottom: 10px;
				.task-content {
					width: 100%;
					margin: 0 auto;
					display: flex;
					.task-content-left {
						cursor: pointer;
						margin-top: 20px;
						margin-left: 40px;
						width: 615px;
						border-right: 1px solid #e4e7ed;
						h2 {
							width: 587px;
							font-size: 18px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
						p {
							width: 595px;
							padding-top: 20px;
							padding-bottom: 20px;
							font-size: 12px;
							color: #575757;
							line-height: 16px;
						}
						ul {
							display: flex;
							li {
								font-weight: bold;
								margin-left: 10px;
								&::after {
									margin-left: 10px;
									content: '/';
									display: inline-block;
								}
							}
							li:last-of-type::after {
								content: '';
							}
							li:first-of-type {
								margin-left: 0;
							}
						}
						button {
							font-size: 12px;
							background-color: transparent;
							color: #00a2e6;
						}
					}
					.task-content-right {
						margin-top: 20px;
						width: 180px;
						margin-left: 20px;
						text-align: center;
						h2 {
							font-size: 16px;
						}
						span {
							font-size: 14px;
						}
						button {
							width: 120px;
							line-height: 24px;
							background: #00a2e6;
							font-size: 14px;
							color: #fff;
							margin: 20px 0;
						}
					}
				}
				.task-content-bottom-img {
					margin-left: 40px;
					img {
						width: 16px;
						height: 16px;
						margin-right: 10px;
					}
					span {
						font-size: 12px;
						color: #575757;
					}
					ul {
						display: flex;
						margin-top: 20px;
						padding-bottom: 20px;
						li {
							margin-right: 40px;
							height: 18px;
							display: flex;
							align-items: center;
						}
					}
				}
			}
			.task-content-font .task-hall-center-content:last-of-type {
				border-bottom: none;
			}
      .task-hall-center-content:hover {
        box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);
        transition: all 0.5s;
      }
		}
	}
}
/deep/.el-menu-vertical-demo {
	max-height: 350px;
	overflow: auto;
	border-right: none;
}
.el-row {
	width: 245px;
	margin-bottom: 20px;
	display: flex;
	flex-wrap: wrap;
}
/deep/.el-checkbox {
	font-size: 14px;
	color: #575757;
}
/deep/.el-checkbox__label {
	color: #000;
}
.block {
	display: flex;
	align-items: center;
	justify-content: center;
}
/deep/.el-pagination {
	margin-top: 20px;
	padding-bottom: 40px;
	text-align: center;
}

/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
	color: #000;
}
/deep/.el-input {
	margin-left: 0;
}
/deep/.el-menu-item {
	line-height: 34px;
}
/deep/.el-button {
	border-radius: 0;
}
</style>
