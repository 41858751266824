import request from './ajax'
import qs from 'qs'
// 任务明细
export const getPrivateInfo = (data) =>
    request({
        method: 'get',
        url: '/task/info/private',
        params: data
    })

export const getTaskConfigData = (data) =>
    request({
        method: 'get',
        url: '/task/info/config',
        data: qs.stringify(data)
    })

// 推荐技能
export const getRecommandskills = (data) =>
    request({
        method: 'get',
        url: '/task/recommandskill',
        params: data,
    })

export const getSkills = (data) =>
    request({
        method: 'get',
        url: '/service/skills',
        params: data
    })


export const getSkillsCategory = (data) =>
    request({
        method: 'get',
        url: '/service/category/list',
        params: data
    })

export const createTask = (data) =>
    request({
        method: 'post',
        url: '/task/create',
        data: qs.stringify(data),

    })

export const updateTask = (data) =>
    request({
        method: 'post',
        url: '/task/update',
        data: qs.stringify(data),

    })

export const getTaskInfo = (data) =>
    request({
        method: 'get',
        url: 'task/info/public',
        params: data,

    })

export const applyFast = (data) =>
    request({
        method: 'post',
        url: 'task/bid/apply/fast',
        data: qs.stringify(data)
    })

export const apply = (data) =>
    request({
        method: 'post',
        url: 'task/bid/apply',
        data: qs.stringify(data),

    })


export const cancel = (data) =>
    request({
        method: 'post',
        url: 'task/bid/apply/cancel',
        data: qs.stringify(data),
    })


export const checkedTaskAuth = (data) =>
    request({
        method: 'post',
        url: 'task/auth',
        data: qs.stringify(data),
    })

export const getTaskAuditInfo = (data) =>
    request({
        method: 'post',
        url: 'task/auditstatus',
        data: qs.stringify(data),
    })

    //技能大厅
export const getTaskHall = (data) => request({
    method: 'get',
    url: '/task/list',
    params: data
})


export const collect = (data) =>
    request({
        method: 'post',
        url: 'task/collect',
        data: qs.stringify(data),
    })

export const uncollect = (data) =>
    request({
        method: 'post',
        url: 'task/collect/cancel',
        data: qs.stringify(data),
    })

export const subscribe = (data) =>
    request({
        method: 'post',
        url: 'task/subscribe',
        data: qs.stringify(data),
    })

export const unsubscribe = (data) =>
    request({
        method: 'post',
        url: 'task/subscribe/cancel',
        data: qs.stringify(data),
    })

export const bidList = (data) =>
    request({
        method: 'get',
        url: 'task/bid/list',
        params: data
    })

export const myBidingTaskList = (data) => request({
    method: 'get',
    url: '/task/userbidtask',
    params: data
})

export const changeOpenLevel = (data) => request({
    method: 'post',
    url: '/task/openlevel',
    data: qs.stringify(data),
})

export const toInviteBid = (data) => request({
    method: 'post',
    url: '/task/bid/invite',
    data: qs.stringify(data),
})


